/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import API from '../constants/API';

const parseUser = (user) => {
  const { metaData, nickname, userId, plainProfileUrl } = user;
  return {
    metaData,
    nickname,
    userId,
    plainProfileUrl,
  };
};

const parseMessage = (m) => {
  const {
    createdAt,
    message,
    messageId,
    messageType,
    name,
    url,
    parentMessageId,
    parentMessageText,
    sendingStatus,
    unreadCount,
    _sender,
  } = m;
  return {
    createdAt,
    message,
    messageId,
    messageType,
    name,
    url,
    parentMessageId,
    parentMessageText,
    sendingStatus,
    unreadCount,
    _sender: parseUser(_sender),
  };
};

const parseChannel = (c) => {
  const {
    createdAt,
    data,
    lastMessage,
    lastMessageUpdatedAt,
    members,
    unreadMessageCount,
    url,
  } = c;
  return {
    createdAt,
    data,
    lastMessage: lastMessage ? parseMessage(lastMessage) : null,
    lastMessageUpdatedAt,
    members: members.map((m) => parseUser(m)),
    unreadMessageCount,
    url,
  };
};

export const onInsertChannels = createAsyncThunk(
  'chat/onInsertChannels',
  (channels) =>
    channels
      .map((c) => parseChannel(c))
      .filter(({ members }) => members.length >= 2),
);

export const onUpdateChannels = createAsyncThunk(
  'chat/onUpdateChannels',
  (channels) =>
    channels
      .map((c) => parseChannel(c))
      .filter(({ members }) => members.length >= 2),
);

export const onInsertMessages = createAsyncThunk(
  'chat/onInsertMessages',
  (messages) => messages.map((m) => parseMessage(m)),
);

export const onUpdateMessages = createAsyncThunk(
  'chat/onUpdateMessages',
  (messages) => messages.map((m) => parseMessage(m)),
);

export const onAddPendingMessage = createAsyncThunk(
  'chat/onAddPendingMessage',
  (m) => parseMessage(m),
);

const initialState = {
  channels: [],
  messages: [],
  pendingMessage: null,
  direction: 'next',
};

const chat = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    onResetChannels: (state, action) => {
      state.channels = [];
    },
    onSetActiveChannel: (state, action) => {
      state.activeChannel = action.payload;
    },
    onLeaveRoom: (state, action) => {
      state.messages = [];
    },
  },
  extraReducers: {
    [onInsertChannels.fulfilled]: (state, action) => {
      state.channels = [...action.payload, ...state.channels].sort((a, b) => {
        if (a.lastMessage && b.lastMessage) {
          return a.lastMessage.createdAt < b.lastMessage.createdAt ? 1 : -1;
        }
        if (a.lastMessage) {
          return -1;
        }
        return 1;
      });
    },
    [onUpdateChannels.fulfilled]: (state, action) => {
      action.payload.forEach((channel) => {
        const index = state.channels.findIndex((c) => c.url === channel.url);
        if (index >= 0) {
          state.channels[index] = channel;
        }
      });
    },
    [onInsertMessages.fulfilled]: (state, action) => {
      state.pendingMessage = null;
      if (!state.messages.length) {
        state.messages = [...action.payload];
        state.direction = 'next';
      } else if (
        action.payload[action.payload.length - 1].createdAt >
        state.messages[0].createdAt
      ) {
        state.messages = [...action.payload, ...state.messages];
        state.direction = 'next';
      } else {
        state.messages = [...state.messages, ...action.payload];
        state.direction = 'prev';
      }
    },
    [onAddPendingMessage.fulfilled]: (state, action) => {
      state.pendingMessage = action.payload;
    },
    [onUpdateMessages.fulfilled]: (state, action) => {
      action.payload.forEach((message) => {
        const index = state.messages.findIndex(
          (m) => m.messageId === message.messageId,
        );
        if (index >= 0) {
          state.messages[index] = message;
        }
      });
    },
  },
});

export const { onResetChannels, onSetActiveChannel, onLeaveRoom } =
  chat.actions;
export default chat.reducer;

export const selectChannels = (state) => state.chat.channels;
export const selectMessages = (state) => state.chat.messages;
export const selectPendingMessage = (state) => state.chat.pendingMessage;
export const selectDirection = (state) => state.chat.direction;
