/* eslint-disable no-nested-ternary */
import { serialize as serializeDefault } from 'object-to-formdata';
import { format as formatFNS, parseISO } from 'date-fns';

export const isDev = false;
// export const isDev = process.env.NODE_ENV === 'development';

export const isTestServer = !!process.env.REACT_APP_TEST_SERVER;
// export const isTestServer = true;

export const localURL = 'http://localhost:3000/';
export const siteURL = isTestServer
  ? 'https://test.tutorhive.kr/'
  : 'https://tutorhive.kr/';
export const localServerURL = 'http://localhost:8000/';
export const serverURL = isTestServer
  ? 'https://test.tutorhive.kr:8000/'
  : 'https://tutorhive.kr:8000/';

export const tutorhiveKakao = 'http://pf.kakao.com/_ddvDV/chat';
export const tutorhiveEmail = 'help@tutorhive.kr';
export const tutorhivePhone = '010-5723-7221';
export const companyStory =
  'https://verbose-bench-eaf.notion.site/5c57368d02254459a0a34dfdadae4b23';
export const tutorhiveTerms =
  'https://verbose-bench-eaf.notion.site/6cdb2314081848fbbecfcb4b9b265b84';
export const tutorhivePrivacy =
  'https://verbose-bench-eaf.notion.site/59acbd8e1bab44b28b014f8d3744a528';
export const tutorhiveFaq =
  'https://verbose-bench-eaf.notion.site/2e4d5a9b183f40129426d82aca1f2171';
export const bankAccount = '110-338-435020';

export const appStoreLink = 'https://apps.apple.com/kr/app/id1578449153';

export const googlePlayLink =
  'https://play.google.com/store/apps/details?id=kr.tutorhive';

export const toIntArray = (arr) =>
  Array.isArray(arr) ? arr.map((val) => +val) : [];
export const toStringArray = (arr) =>
  Array.isArray(arr) ? arr.map((val) => `${val}`) : [];

const regexStr = '^[0-9]*$';
export const numericRegex = /[^0-9.]/g;

export const onlyNumber = (e) => {
  if (
    [46, 8, 9, 27, 13, 110, 190].indexOf(e.keyCode) !== -1 ||
    // Allow: Ctrl+A
    (e.keyCode === 65 && e.metaKey === true) ||
    // Allow: Ctrl+C
    (e.keyCode === 67 && e.metaKey === true) ||
    // Allow: Ctrl+V
    (e.keyCode === 86 && e.metaKey === true) ||
    // Allow: Ctrl+X
    (e.keyCode === 88 && e.metaKey === true) ||
    // Allow: home, end, left, right
    (e.keyCode >= 35 && e.keyCode <= 39)
  ) {
    // let it happen, don't do anything
    return;
  }
  const ch = String.fromCharCode(e.keyCode);
  const regEx = new RegExp(regexStr);
  if (regEx.test(ch)) return;
  e.preventDefault();
};

export const onlyPasteNumber = (e) => {
  e.preventDefault();
  const pastedInput = e.clipboardData.getData('text/plain').replace(/\D/g, ''); // get a digit-only string
  document.execCommand('insertText', false, pastedInput);
};

const emailRegex = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi;
const phoneRegex = /([/][a-zA-Z-_]{0,})?([+\d-.](?=[\d-.]{7,})[\d -.]{7,})/g;

export const emailPhoneFilter = (text) => {
  if (text) {
    return text.replace(emailRegex, '******').replace(phoneRegex, ($0, $1) => {
      if ($0.match(/20\d{2}-20\d{2}/)) {
        return $0;
      }
      return $1 ? $0 : '******';
    });
    // replace(/kakao talk|kakaotalk|kakao|카카오톡|카카오|카톡|아이디|카톡|(^|\s)id:?(?= )/g, '******')
  }
  return text;
};

export const isValidDate = (d) => {
  if (Object.prototype.toString.call(d) === '[object Date]') {
    // it is a date
    if (Number.isNaN(d.getTime())) {
      return false;
    }
    return true;
  }
  return false;
};

export const validDate = (date) => {
  if (date) {
    if (typeof date === 'string' && !date.includes('Z')) {
      const d = new Date(date.replace(/\//g, '-').replace(/.000000/g, ''));
      if (isValidDate(d)) {
        return d;
      }
    }

    const d = new Date(date);

    if (isValidDate(d)) {
      return d;
    }
  }
  return null;
};

export const serialize = (object, options = {}) => {
  if (object) {
    return serializeDefault(object, {
      indices: true,
      ...options,
    });
  }
  return object;
};

export const format = (date, format, options) => {
  if (!date) {
    return null;
  }
  if (typeof date === 'string') {
    return formatFNS(parseISO(date), format, options);
  }
  return formatFNS(date, format, options);
};

export const isImage = (name) =>
  ['jpg', 'jpeg', 'png'].indexOf(name.split('.').pop()) !== -1;
