import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import groupBy from 'lodash.groupby';
import { normalize, schema } from 'normalizr';

import API from '../constants/API';
import axios from '../constants/axios';

const highschoolsEntity = new schema.Entity('highschools', {});

const highschoolsAdapter = createEntityAdapter({
  sortComparer: (a, b) => a.name1.localeCompare(b.name1),
});

const countriesLabel = {
  UK: '영국',
  USA: '미국',
  CN: '중국',
  HK: '홍콩',
  JP: '일본',
  CA: '캐나다',
  AU: '호주',
  'N/A': '기타',
  SP: '싱가폴',
  KR: '한국',
};

const universities = new schema.Entity('universities', {});
const countriesEntity = new schema.Entity('countries', {
  universities: [universities],
});

export const fetchHighschools = createAsyncThunk(
  'highschools/fetchHighschools',
  async (_, { getState }) => {
    const { data } = await axios.get(API.getHighschools);
    const normalized = normalize(data, [highschoolsEntity]);

    return normalized.entities;
  },
);

const universitiesAdapter = createEntityAdapter({
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});

export const fetchUniversities = createAsyncThunk(
  'highschools/fetchUniversities',
  async (_, { getState }) => {
    const { data } = await axios.get(API.getUniversities);
    // Group the universities by their countries
    const universitiesByCountries = Object.entries(groupBy(data, 'nation')).map(
      ([countries, universities]) => ({
        id: countries,
        label: countriesLabel[countries],
        universities,
      }),
    );
    const normalized = normalize(universitiesByCountries, [countriesEntity]);

    return normalized.entities;
  },
);

const countriesAdapter = createEntityAdapter();

const initialState = {
  highschools: highschoolsAdapter.getInitialState(),
  universities: universitiesAdapter.getInitialState(),
};

const schools = createSlice({
  name: 'schools',
  initialState,
  extraReducers: {
    [fetchHighschools.fulfilled]: (state, action) => {
      action.payload.highschools &&
        highschoolsAdapter.setAll(
          state.highschools,
          action.payload.highschools,
        );
    },
    [fetchUniversities.fulfilled]: (state, action) => {
      action.payload &&
        universitiesAdapter.setAll(
          state.universities,
          action.payload.universities,
        );
    },
  },
});

// const countries = createSlice({
//   name: 'countries',
//   initialState: countriesAdapter.getInitialState(),
//   extraReducers: {
//     [fetchUniversities.fulfilled]: (state, action) => {
//       countriesAdapter.setAll(state, action.payload.countries);
//     },
//   },
// });

export default schools.reducer;

export const {
  selectEntities: selectHighschoolsEntities,
  selectAll: selectAllHighschools,
} = highschoolsAdapter.getSelectors((state) => state.schools.highschools);

export const highschoolsOptionsSelector = createSelector(
  selectAllHighschools,
  (highschools) =>
    highschools.map(({ id, name1 }) => ({ value: id, label: name1 })),
);

// export const {
//   selectAll: selectAllCountries,
//   selectEntities: selectCountriesEntities,
//   selectIds: selectCountriesIds,
// } = countriesAdapter.getSelectors((state) => {
//   return state.countries;
// });

export const {
  selectEntities: selectUniversitiesEntities,
  selectAll: selectAllUniversities,
} = universitiesAdapter.getSelectors((state) => {
  return state.schools.universities;
});

export const universitiesOptionsSelector = createSelector(
  selectAllUniversities,
  (universities) =>
    universities.map(({ id, name, abbreviation, kr }) => ({
      value: id,
      label:
        name + (abbreviation ? ` ${abbreviation}` : '') + (kr ? ` ${kr}` : ''),
    })),
);

export const universitiesCountriesOptionsSelector = createSelector(
  selectAllUniversities,
  (universities) => {
    const universitiesByCountry = Object.entries(
      groupBy(universities, 'nation'),
    ).map(([country, universities]) => ({ id: country, universities }));
    return universitiesByCountry.map(({ id, universities }) => ({
      value: id,
      label: countriesLabel[id],
      universities: universities.map((univ) => univ.id),
    }));
  },
);

export const countriesEntitySelector = createSelector(
  selectAllUniversities,
  (universities) => {
    const universitiesByCountry = Object.entries(
      groupBy(universities, 'nation'),
    ).map(([country, universities]) => ({ id: country, universities }));
    return universitiesByCountry.reduce((a, v) => {
      return {
        ...a,
        [v.id]: {
          label: countriesLabel[v.id],
          universities: v.universities.map((univ) => univ.id),
        },
      };
    }, {});
  },
);
