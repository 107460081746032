import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { createModal } from 'react-modal-promise';
import { Link } from 'react-router-dom';

import Modal from 'components/ui/Modal';
import { ReactComponent as ErrorIcon } from 'assets/icons/error.svg';

const AlertErrorModal = ({ isOpen, onReject, message, subMessage }) => {
  const { t } = useTranslation('common');

  const closeModal = () => {
    onReject();
  };

  const messageParsed = useMemo(() => {
    if (message) {
      if (message.indexOf('code 500') !== -1) {
        return <Trans i18nKey="common:unexpectedErrorCs" />;
      }
      if (message.indexOf('Network Error') !== -1) {
        return t('unexpectedError');
      }
      return message;
    }
    return message;
  }, [message]);

  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal} className="modal-error">
      <div className="modal-icon">
        <ErrorIcon />
      </div>
      <p className="modal-message">{messageParsed}</p>
      {!!subMessage && <p className="modal-sub-message">{subMessage}</p>}
    </Modal>
  );
};

AlertErrorModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onReject: PropTypes.func.isRequired,
  message: PropTypes.string,
  subMessage: PropTypes.string,
};

AlertErrorModal.defaultProps = {
  message: '',
  subMessage: undefined,
};

const useAlertErrorModal = () => createModal(AlertErrorModal);
export default useAlertErrorModal;
