import { useMutation } from 'react-query';

import API from '../../constants/API';
import axios from '../../constants/axios';

const useMessageSent = () => {
  return useMutation((form) => {
    return axios.post(API.messageSent, form);
  });
};

export default useMessageSent;
