import throttle from 'lodash.throttle';
import { useEffect, useState } from 'react';

const useScrollHandler = () => {
  const [scroll, setScroll] = useState(true);

  useEffect(() => {
    const onScroll = () => {
      const scrollCheck = window.scrollY < 10;
      if (scrollCheck !== scroll) {
        setScroll(scrollCheck);
      }
    };

    // setting the event handler from web API
    const throttledScroll = throttle(onScroll, 100);
    document.addEventListener('scroll', throttledScroll);

    // cleaning up from the web API
    return () => {
      throttledScroll.cancel();
      document.removeEventListener('scroll', throttledScroll);
    };
  }, [scroll]);

  return scroll;
};

export default useScrollHandler;
