/* eslint-disable no-unreachable */
/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Redirect, useLocation } from 'react-router-dom';

import useUser from 'shared/hooks/useUser';
import { isDev } from 'shared/utils/utils';
import Route from './Route';

const ProtectedRoute = ({
  children,
  type,
  onlyMine,
  condition,
  redirectTo,
  path,
  ...rest
}) => {
  const { thisUser, isTutor, isStudent, myId, isAdmin } = useUser();
  const { state } = useLocation();

  // if you want to access all routes in dev
  const allAccess = true;

  const isMissingField = useMemo(() => {
    if (isDev || !thisUser) {
      return false;
    }

    if (isTutor && thisUser.personal.registrationStep === 4) {
      if (!thisUser.highSchool && !thisUser.highSchoolName) {
        return true;
      }
      if (thisUser.schoolList.find(({ proof }) => !proof)) {
        return true;
      }
    }

    return (
      thisUser.personal.registrationStep === 4 &&
      (!thisUser.personal.phone ||
        (isTutor &&
          (!thisUser.personal.bankId ||
            !thisUser.personal.bankNum ||
            !thisUser.personal.nameOnCard ||
            !thisUser.personal.dob)))
    );
  }, [thisUser, isTutor]);

  if (allAccess && isDev) {
    return <Route {...rest} path={path} render={() => children} />;
  }

  const registrationStepCheck = () => {
    if (isStudent) {
      if (thisUser.personal.registrationStep === 1) {
        return <Redirect to={{ pathname: '/register-student' }} />;
      }
      if (thisUser.personal.registrationStep === 2) {
        return <Redirect to={{ pathname: '/welcome-student' }} />;
      }
    }

    if (isTutor) {
      if (thisUser.personal.registrationStep === 1) {
        return <Redirect to={{ pathname: '/register-tutor' }} />;
      }
      if (
        thisUser.personal.registrationStep === 2 ||
        thisUser.personal.registrationStep === 5
      ) {
        return <Redirect to={{ pathname: '/confirm-register-tutor' }} />;
      }
      if (thisUser.personal.registrationStep === 3) {
        return <Redirect to={{ pathname: `/tutors/${myId}/register` }} />;
      }
    }

    return false;
  };

  if (isMissingField) {
    return (
      <Route
        {...rest}
        path={path}
        render={() =>
          path !== '/required-info' ? (
            <Redirect to="/required-info" />
          ) : (
            children
          )
        }
      />
    );
  }

  if (path === '/required-info') {
    return <Route {...rest} path={path} render={() => <Redirect to="/" />} />;
  }

  return (
    <Route
      {...rest}
      path={path}
      render={(props) => {
        if (condition !== null) {
          if (!condition) {
            return <Redirect to={{ pathname: redirectTo }} />;
          }
        } else {
          if (type === 'main') {
            const check = registrationStepCheck();
            if (check) {
              return check;
            }
          }
          if (type === 'tutor' && !isTutor && !isAdmin) {
            return <Redirect to={{ pathname: state || '/' }} />;
          }
          if (type === 'student' && !isStudent && !isAdmin) {
            return <Redirect to={{ pathname: state || '/' }} />;
          }
          if (type === 'private') {
            if (!thisUser) {
              return (
                <Redirect
                  to={{ pathname: '/login', state: props.location.pathname }}
                />
              );
            }
            if (path === '/edit-profile') {
              return (
                <Redirect
                  to={{
                    pathname: `${
                      (isTutor ? '/tutors/' : '/students/') + myId
                    }/edit-profile`,
                  }}
                />
              );
            }
            const check = registrationStepCheck();
            if (check) {
              return check;
            }
          }
          if (type === 'guest' && thisUser) {
            if (thisUser.recovered) {
              return <Redirect to={{ pathname: 'welcome-back' }} />;
            }
            if (isTutor && state?.includes('tutor')) {
              return <Redirect to={{ pathname: redirectTo }} />;
            }
            return <Redirect to={{ pathname: state || redirectTo }} />;
          }
        }

        if (onlyMine) {
          const check = registrationStepCheck();
          if (check) {
            if (props.match.url !== check.props.to.pathname) {
              return check;
            }
          }
          const id = props.match.params?.id;
          if (myId !== +id) {
            return <Redirect to={{ pathname: '/' }} />;
          }
        }
        return children;
      }}
    />
  );
};

ProtectedRoute.propTypes = {
  children: PropTypes.node,
  type: PropTypes.string,
  condition: PropTypes.bool,
  redirectTo: PropTypes.string,
  onlyMine: PropTypes.bool,
  path: PropTypes.string,
};

ProtectedRoute.defaultProps = {
  children: null,
  type: 'private',
  condition: null,
  redirectTo: '/',
  onlyMine: false,
  path: '',
};

export default ProtectedRoute;
