import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';

import API from '../../constants/API';
import axios from '../../constants/axios';
import useSimpleLoading from '../useSimpleLoading';

const useLogout = () => {
  const dispatch = useDispatch();
  const { onLoading, onLoadingEnd, onError } = useSimpleLoading(dispatch);

  return useMutation(
    () => {
      return axios.get(API.logout);
    },
    {
      onMutate: onLoading,
      onSettled: onLoadingEnd,
      onError,
    },
  );
};

export default useLogout;
