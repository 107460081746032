import './Avatar.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import BrokenImage from 'assets/broken_image.svg';
import NoProfile from 'assets/no_profile.svg';

const Avatar = ({ size, src, ...rest }) => {
  const [avatar, setAvatar] = useState(null);

  const onError = (e) => {
    if (e.target.src !== BrokenImage && e.target.src !== NoProfile) {
      e.onerror = null;
      if (
        e.target.src ===
        'http://7xl71n.com1.z0.glb.clouddn.com/tutor_profiles/images/default.backuptutorpic.png'
      ) {
        e.target.src = NoProfile;
        setAvatar(NoProfile);
      } else {
        e.target.src = BrokenImage;
        setAvatar(BrokenImage);
      }
    }
  };

  const onLoad = () => {
    if (!avatar) {
      setAvatar(src || NoProfile);
    }
  };

  useEffect(() => {
    setAvatar(src);
  }, [src]);

  return (
    <div className="avatar" style={{ width: `${size}px`, height: `${size}px` }}>
      {avatar !== null && (
        <span style={{ backgroundImage: `url(${avatar})` }} {...rest} />
      )}
      <img
        src={src || NoProfile}
        alt="아바타"
        onLoad={onLoad}
        onError={onError}
      />
    </div>
  );
};

Avatar.propTypes = {
  size: PropTypes.number,
  src: PropTypes.string,
};

Avatar.defaultProps = {
  size: 40,
  src: BrokenImage,
};

export default Avatar;
