import './StarRating.scss';

import PropTypes from 'prop-types';
import React, { useState } from 'react';

import cx from 'utils/cx';
import Star from './Star';

const StarRating = ({
  rating,
  changeRating,
  color,
  starDimension,
  starSpacing,
  showRating,
}) => {
  const numberOfStarsArray = new Array(5).fill();

  const [highestStarHovered, setHighestStarHovered] = useState(-Infinity);
  const fillId = `starGrad${Math.random().toFixed(15).slice(2)}`;

  const hoverOverStar = (starRating) => () => setHighestStarHovered(starRating);

  const unHoverOverStar = () => setHighestStarHovered(-Infinity);

  const offsetValue = () => {
    const ratingIsInteger = Number.isInteger(rating);
    let offsetValue = '0%';
    if (!ratingIsInteger) {
      const firstTwoDecimals = rating.toFixed(2).split('.')[1].slice(0, 2);
      offsetValue = `${firstTwoDecimals}%`;
    }
    return offsetValue;
  };

  return (
    <div className={cx('star-ratings', color)}>
      <svg className="star-grad">
        <defs>
          <linearGradient id={fillId} x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" className="stop-color-first" />
            <stop offset={offsetValue()} className="stop-color-first" />
            <stop offset={offsetValue()} className="stop-color-final" />
            <stop offset="100%" className="stop-color-final" />
          </linearGradient>
        </defs>
      </svg>
      {numberOfStarsArray.map((_, index) => {
        const starRating = index + 1;
        const isStarred = starRating <= rating;

        // hovered only matters when changeRating is true
        const hoverMode = highestStarHovered > 0;
        const isHovered = starRating <= highestStarHovered;
        const isCurrentHoveredStar = starRating === highestStarHovered;

        // only matters when changeRating is false
        // given star 5 and rating 4.2:  5 > 4.2 && 4 < 4.2;
        const isPartiallyFullStar =
          starRating > rating && starRating - 1 < rating;

        const isFirstStar = starRating === 1;
        const isLastStar = starRating === 5;

        return (
          <Star
            key={starRating}
            fillId={fillId}
            changeRating={changeRating ? () => changeRating(starRating) : null}
            hoverOverStar={changeRating ? hoverOverStar(starRating) : null}
            unHoverOverStar={changeRating ? unHoverOverStar : null}
            isStarred={isStarred}
            isPartiallyFullStar={isPartiallyFullStar}
            isHovered={isHovered}
            hoverMode={hoverMode}
            isCurrentHoveredStar={isCurrentHoveredStar}
            isFirstStar={isFirstStar}
            isLastStar={isLastStar}
            starDimension={starDimension}
            starSpacing={starSpacing}
            color={color}
          />
        );
      })}
      {showRating && <span className="rating-value">{rating.toFixed(2)}</span>}
    </div>
  );
};

StarRating.propTypes = {
  rating: PropTypes.number,
  changeRating: PropTypes.func,
  color: PropTypes.string,
  starDimension: PropTypes.number,
  starSpacing: PropTypes.number,
  showRating: PropTypes.bool,
};

StarRating.defaultProps = {
  rating: 0,
  changeRating: null,
  color: 'primary',
  starDimension: 16,
  starSpacing: 0,
  showRating: false,
};

export default StarRating;
