import './Header.scss';

import PropTypes from 'prop-types';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import useUser from 'shared/hooks/useUser';
import { isDev, isTestServer } from 'shared/utils/utils';

import useScrollHandler from 'hooks/useScrollHandler';
import { ReactComponent as BurgerIcon } from 'assets/icons/burger.svg';
import { ReactComponent as Logo } from 'assets/logo.svg';
import cx from 'utils/cx';
import { tutorsLink } from 'utils/utils';
import IconButton from './ui/IconButton';

const useShowHeader = () => {
  const location = useLocation();

  if (
    location.pathname.includes('newsletter') ||
    location.pathname === '/ckeditor'
  ) {
    return false;
  }

  return true;
};

const Header = ({ isNavOpen, toggleNav, isMain }) => {
  const top = useScrollHandler();
  const { thisUser, isTutor } = useUser();
  const showHeader = useShowHeader();

  if (!showHeader) {
    return null;
  }

  const getPostLandingSubHeader = () => (
    <ul className="header-nav">
      <li>
        <Link to="/" className="active">
          홈
        </Link>
      </li>
      <li>
        {!isTutor ? (
          <Link to={tutorsLink}>튜터찾기</Link>
        ) : (
          <Link to="/students">학생찾기</Link>
        )}
      </li>
      <li>
        <Link to="/mypage">마이 페이지</Link>
      </li>
    </ul>
  );

  const getLabel = () => {
    if (isDev) {
      return <span className="server-tag">Local Server</span>;
    }
    if (isTestServer) {
      return <span className="server-tag">Test Server</span>;
    }
    return null;
  };

  const getHeader = () => (
    <div className="header-box">
      <div className="logo-wrapper">
        {getLabel()}
        <h1>
          <Link to="/" aria-label="Tutor Hive">
            <Logo />
          </Link>
        </h1>
      </div>
      <div className="menu-wrapper">
        {!thisUser && (
          <Link to="/login" className="login-link">
            로그인하기
          </Link>
        )}
        <IconButton onClick={toggleNav}>
          <BurgerIcon />
        </IconButton>
      </div>
    </div>
  );

  return (
    <header
      className={cx(
        'header-wrapper',
        { 'is-shadow': !top },
        { 'is-nav-open': isNavOpen },
      )}
    >
      {getHeader()}
      {isMain && thisUser && getPostLandingSubHeader()}
    </header>
  );
};

Header.propTypes = {
  isNavOpen: PropTypes.bool.isRequired,
  toggleNav: PropTypes.func.isRequired,
  isMain: PropTypes.bool.isRequired,
};

export default Header;
