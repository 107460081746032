export default {
  loginWithCredentials: '/data/login',
  logout: '/data/logout',
  getUser: '/tutor_hive/this_user',
  getStudents: '/tutor_hive/student_profile',
  getStudentById: '/tutor_hive/student_profile',
  getTutors: '/tutor_hive/tutor_profile',
  getTutorById: '/tutor_hive/tutor_profile',
  getCurriculumsAndUniversities: '/data/api/teacher_search',
  getCurriculums: '/tutor_hive/api/newCurriculums',
  getLocations: '/tutor_hive/api/locations',
  registerStudent: '/tutor_hive/student_profile/register',
  welcomeRegisterStudent: '/tutor_hive/student_profile/welcome',
  studentEditTuition: '/tutor_hive/student_profile/tuition',
  studentEditProfile: '/tutor_hive/student_profile/edit',
  registerTutorPre: 'tutor_hive/tutor_profile/store_pre',
  registerTutorPost: 'tutor_hive/tutor_profile/store_post',
  getMain: '/tutor_hive/main',
  likeStudent: '/tutor_hive/student_profile/like',
  likeTutor: '/tutor_hive/tutor_profile/like',
  blockTutor: '/tutor_hive/tutor_profile/block',
  getClasses: '/tutor_hive/tuition/dashboard',
  getClassInfo: '/tutor_hive/tuition/view',
  issueInvoice: '/tutor_hive/tuition/invoice',
  editInvoice: '/tutor_hive/tuition/edit',
  deleteInvoice: '/tutor_hive/tuition/delete',
  writeReport: '/tutor_hive/tuition/report',
  deleteAccount: '/tutor_hive/user_profile/delete',
  register: '/tutor_hive/user_profile/register',
  canReview: '/tutor_hive/tuition/can_review',
  toReview: '/tutor_hive/tuition/to_review',
  writeReview: '/tutor_hive/tuition/review',
  getReviews: '/tutor_hive/tutor_profile/getReviews',
  getFavorite: '/tutor_hive/user_profile/like_list',
  getHighschools: '/tutor_hive/api/highschool',
  resetPassword: '/tutor_hive/user_profile/forget_password_reset',
  changePassword: '/tutor_hive/user_profile/reset_password',
  hideTutorProfile: '/tutor_hive/tutor_profile/hide',
  writeDiscussion: '/tutor_hive/discussion/new',
  editDiscussion: '/tutor_hive/discussion/edit',
  deleteDiscussion: '/tutor_hive/discussion/delete',
  likeDiscussion: '/tutor_hive/discussion/like',
  getTempDiscussion: '/tutor_hive/discussion/get_temp',
  addComment: '/tutor_hive/discussion/new_comment',
  editComment: '/tutor_hive/discussion/edit_comment',
  deleteComment: '/tutor_hive/discussion/delete_comment',
  likeComment: '/tutor_hive/discussion/like_comment',
  getDiscussions: '/tutor_hive/discussion/index',
  getDiscussion: '/tutor_hive/discussion/show',
  getBestDiscussions: '/tutor_hive/discussion/best_discussions',
  getMyDiscussions: '/tutor_hive/discussion/my_discussions',
  getMyLikedDiscussions: '/tutor_hive/discussion/my_likes',
  getMyComments: '/tutor_hive/discussion/my_comments',
  uploadImage: '/tutor_hive/api/image_upload',
  activateEmail: '/tutor_hive/user_profile/activate',
  forgotPassword: '/tutor_hive/user_profile/forget_password',
  getUniversities: '/tutor_hive/api/colleges',
  isNicknameAvailable: '/tutor_hive/user_profile/nickname_unique',
  registerGoogle: '/tutor_hive/user_profile/register_google',
  loginGoogle: '/tutor_hive/user_profile/login_google',
  registerKakao: '/tutor_hive/user_profile/register_kakao',
  loginKakao: '/tutor_hive/user_profile/login_kakao',
  registerApple: '/tutor_hive/user_profile/register_apple',
  loginApple: '/tutor_hive/user_profile/login_apple',
  getCountries: '/tutor_hive/api/countries',
  getVerificationCode: '/tutor_hive/user_profile/get_verification_code',
  updatePhone: '/tutor_hive/user_profile/update_phone',
  pay: '/tutor_hive/tuition/pay',
  getAds: '/tutor_hive/advert/all',
  getAdsById: '/tutor_hive/advert/',
  resendActivation: '/tutor_hive/user_profile/resend_activation',
  getTempReport: '/tutor_hive/tuition/get_temp_report',
  getTempPostInterview: '/tutor_hive/tutor_profile/get_temp_post',
  hasOnGoingClass: '/tutor_hive/tuition/in_progress',
  reportReview: '/tutor_hive/tutor_profile/report_review',
  verifyBank: '/tutor_hive/tuition/bank_verify',
  // message
  messageSent: '/tutor_hive/message/sent',
  canSendMessage: '/tutor_hive/message/can_send',
  // Stories
  getStories: '/tutor_hive/story/index',
  showStory: '/tutor_hive/story/show/',
  newStory: '/tutor_hive/story/new',
  likeStory: '/tutor_hive/story/like',
  payStory: '/tutor_hive/story/pay',
  myStories: '/tutor_hive/story/my_story',
  // Metadata
  getMetadata: '/tutor_hive/api/metadata',
  // Partners
  newPartner: '/tutor_hive/partner/new',
  editPartner: '/tutor_hive/partner/edit',
  getPartnerById: '/tutor_hive/partner/show/',
  getPartners: '/tutor_hive/partner/index',
  reviewPartner: '/tutor_hive/partner/review',
  likePartner: '/tutor_hive/partner/like',
  hidePartnerProfile: '/tutor_hive/partner/hide',
  getPartnerTemp: '/tutor_hive/partner/get_temp',
  getPartnerLikeList: 'tutor_hive/partner/like_list',
};
