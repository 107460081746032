import 'intersection-observer';

import React from 'react';
import ReactDOM from 'react-dom';
import ModalContainer from 'react-modal-promise';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import storage from 'redux-persist/lib/storage';

import './index.scss';
import configureStore from 'shared/store/store';
import './i18n';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

function noop() {}

// if (process.env.NODE_ENV !== 'development') {
//   console.log = noop;
//   console.warn = noop;
//   console.error = noop;
// }

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: (failureCount, error) => {
        return error.status !== 'fail' && failureCount < 1;
      },
    },
    mutations: {
      retry: false,
    },
  },
});

const store = configureStore(storage);
const persistor = persistStore(store);

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <ModalContainer />
          <App />
        </Router>
      </PersistGate>
    </Provider>
    <ReactQueryDevtools initialIsOpen />
  </QueryClientProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
