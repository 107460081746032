import './IconButton.scss';

import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';

import cx from 'utils/cx';

const IconButton = forwardRef(
  ({ className, children, label, ...rest }, ref) => (
    <button
      ref={ref}
      type="button"
      className={cx('icon-btn', className)}
      aria-label={label}
      {...rest}
    >
      {children}
    </button>
  ),
);

IconButton.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  label: PropTypes.string,
};

IconButton.defaultProps = {
  className: null,
  label: 'icon-btn',
};

export default IconButton;
