/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  errorMessage: null,
  saveDate: null,
  isLoading: false,
  chatConnected: false,
  error: null,
  isMain: false,
};

const common = createSlice({
  name: 'common',
  initialState,
  reducers: {
    onError(state, action) {
      state.error = action.payload;
    },
    onOpenSnackBar(state, action) {
      state.saveDate = action.payload;
    },
    onLoading(state, action) {
      state.isLoading = action.payload;
    },
    onChatConnection(state, action) {
      state.chatConnected = action.payload;
    },
    onSetMain(state, action) {
      state.isMain = action.payload;
    },
  },
});

export const {
  onError,
  onOpenSnackBar,
  onLoading,
  onChatConnection,
  onSetMain,
} = common.actions;

export const selectIsLoading = (state) => state.common.isLoading;
export const selectChatConnected = (state) => state.common.chatConnected;
export const selectError = (state) => state.common.error;
export const selectIsMain = (state) => state.common.isMain;

export default common.reducer;

export const getBase64 = (file) =>
  new Promise((resolve) => {
    let baseURL = '';
    // Make new FileReader
    const reader = new FileReader();

    // Convert the file to base64 text
    reader.readAsDataURL(file);

    // on reader load somthing...
    reader.onload = () => {
      baseURL = reader.result;
      resolve(baseURL);
    };
  });
