/* eslint-disable react/button-has-type */
import './FakeLink.scss';

import PropTypes from 'prop-types';
import React from 'react';

import cx from 'utils/cx';

const FakeLink = ({ className, onClick, children, isButton, type }) => {
  const onFakeLinkClick = (e) => {
    e.preventDefault();
    onClick(e);
  };

  if (isButton) {
    return (
      <button
        type={type}
        className={cx('fake-link', className)}
        onClick={onClick}
      >
        {children}
      </button>
    );
  }

  return (
    <a href="" className={className} onClick={onFakeLinkClick}>
      {children}
    </a>
  );
};

FakeLink.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node,
  isButton: PropTypes.bool,
  type: PropTypes.string,
};

FakeLink.defaultProps = {
  className: null,
  onClick: () => {},
  isButton: false,
  type: 'button',
  children: null,
};

export default FakeLink;
