import { useCallback } from 'react';

import axios from 'shared/constants/axios';
import useKakaoAPI from 'shared/hooks/login/useKakaoAPI';
import { serverURL } from 'shared/utils/utils';

const useKakao = () => {
  const { kakaoRegisterMutation, kakaoLoginMutation } = useKakaoAPI();

  const kakaoSignOut = useCallback(async () => {
    if (window.Kakao.Auth.getAccessToken()) {
      // Try to logout from Kakao Service as well
      await axios.get('https://kauth.kakao.com/oauth/logout', {
        baseURL: '',
        params: {
          client_id: 'ec98197e8db254036ab8ef0937717582',
          logout_redirect_uri: `${serverURL}/data/logout`,
        },
      });
    }
  }, []);

  const onKakaoAuth = useCallback((type) => {
    window.Kakao.Auth.login({
      success(authObj) {
        // alert(JSON.stringify(authObj));
        window.Kakao.Auth.setAccessToken(authObj.access_token);
        window.Kakao.API.request({
          url: '/v2/user/me',
          async success(response) {
            const { email, profile, phone_number } = response.kakao_account;
            const { nickname, profile_image_url } = profile;
            if (type) {
              kakaoRegisterMutation.mutate(
                {
                  email,
                  type,
                  nickname,
                  profileImg: profile_image_url,
                  phoneNumber: phone_number,
                },
                { onError: () => kakaoSignOut() },
              );
            } else {
              kakaoLoginMutation.mutate(
                {
                  email,
                  nickname,
                  profileImg: profile_image_url,
                  phoneNumber: phone_number,
                },
                { onError: () => kakaoSignOut() },
              );
            }
          },
          fail(error) {
            console.log(error);
          },
        });
      },
      fail(err) {
        alert(JSON.stringify(err));
      },
    });
  }, []);

  return { onKakaoAuth, kakaoSignOut };
};

export default useKakao;
