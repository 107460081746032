import './SmartBanner.scss';
import React, { useState } from 'react';
import ReactModal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { selectLastShown, setLastShown } from 'shared/store/persist';
import { appStoreLink, googlePlayLink } from 'shared/utils/utils';

import { ReactComponent as AppIcon } from 'assets/app_icon.svg';
import { isWebApp } from 'utils/utils';
import Button from './ui/Button';
import ExternalLink from './ui/ExternalLink';
import StarRating from './ui/StarRating';

const getMobileOperatingSystem = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (/android/i.test(userAgent)) {
    return 'android';
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'ios';
  }

  return 'unknown';
};

const getLink = () => {
  const os = getMobileOperatingSystem();
  return os === 'ios' ? appStoreLink : googlePlayLink;
};

const diffDays = (date1, date2) => {
  const diffTime = Math.abs(new Date(date2) - new Date(date1));
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
};

const SmartBanner = () => {
  const location = useLocation();
  const lastShown = useSelector(selectLastShown);
  const [isOpen, setIsOpen] = useState(
    !isWebApp &&
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent,
      ) &&
      (!lastShown || diffDays(lastShown, new Date()) > 1),
  );
  const dispatch = useDispatch();

  const closeSmartBanner = () => {
    setIsOpen(false);
    dispatch(setLastShown(new Date().toISOString()));
  };

  const onOpenApp = () => {
    const now = new Date().valueOf();
    setTimeout(() => {
      if (new Date().valueOf() - now > 100) return;
      window.location = getLink();
    }, 500);
    window.location = `tutorhive:/${location.pathname}`;
    closeSmartBanner();
  };

  return (
    <ReactModal
      overlayClassName="modal-overlay"
      bodyOpenClassName="smartbanner-open"
      className="smartbanner"
      isOpen={isOpen}
      closeTimeoutMS={75}
    >
      <div className="inner row lg-padding">
        <div className="smartbanner-heading">
          <AppIcon />
          <div className="smartbanner-heading-content">
            <p>새로워진 튜터하이브 앱으로 </p>
            <p>더욱 편리하게 이용하세요</p>
            <div className="stars-container">
              <StarRating rating={5} />
              <ExternalLink href={getLink()}>앱스토어 후기</ExternalLink>
            </div>
          </div>
        </div>
        <Button onClick={onOpenApp}>튜터하이브 앱으로 보기</Button>
        <Button onClick={closeSmartBanner}>모바일 웹으로 볼게요</Button>
      </div>
    </ReactModal>
  );
};

export default SmartBanner;
