import { useSelector } from 'react-redux';

import {
  selectHoneyPoint,
  selectIsPartner,
  selectIsStudent,
  selectIsTutor,
  selectMyId,
  selectUser,
} from '../store/login';

const useUser = () => {
  const thisUser = useSelector(selectUser);
  const isTutor = useSelector(selectIsTutor);
  const isStudent = useSelector(selectIsStudent);
  const myId = useSelector(selectMyId);
  const honeyPoint = useSelector(selectHoneyPoint);
  const isPartner = useSelector(selectIsPartner);

  return {
    thisUser,
    isTutor,
    isStudent,
    myId,
    honeyPoint,
    isPartner,
  };
};

export default useUser;
