import { useCallback, useEffect } from 'react';

import useGoogleAPI from 'shared/hooks/login/useGoogleAPI';

const useGoogle = (ref, methods) => {
  const { googleRegisterMutation, googleLoginMutation } = useGoogleAPI();

  const initGoogle = () =>
    window.gapi.auth2.init({
      client_id:
        '1071945706707-57ook843recbgj2vt4986s6h9e7hed7s.apps.googleusercontent.com',
      cookiepolicy: 'single_host_origin',
    });

  const googleSignOut = useCallback(() => {
    const auth2 = window.gapi.auth2.getAuthInstance();

    if (auth2) {
      if (auth2.isSignedIn.get()) {
        auth2.signOut();
      }
    } else {
      initGoogle().then((auth2) => {
        if (auth2.isSignedIn.get()) {
          auth2.signOut();
        }
      });
    }
  }, []);

  const onGoogleAuth = useCallback(
    async (googleUser) => {
      const profile = googleUser.getBasicProfile();
      const email = profile.getEmail();
      const { id_token } = googleUser.getAuthResponse();

      if (methods) {
        const result = await methods.trigger('type');
        if (!result) {
          return;
        }
        const { type } = methods.getValues();
        googleRegisterMutation.mutate(
          { email, type, token: id_token },
          { onError: () => googleSignOut() },
        );
      } else {
        googleLoginMutation.mutate(
          { email, token: id_token },
          { onError: () => googleSignOut() },
        );
      }
    },
    [methods],
  );

  useEffect(() => {
    if (ref) {
      const attachSignin = (auth2) => {
        auth2.attachClickHandler(
          ref.current,
          {},
          async (googleUser) => {
            onGoogleAuth(googleUser);
          },
          (error) => {
            console.error(error);
          },
        );
      };
      if (window.gapi.auth2) {
        window.gapi.load('auth2', () => {
          attachSignin(window.gapi.auth2.getAuthInstance());
        });
      } else {
        window.addEventListener('gapiLoaded', () =>
          window.gapi.load('auth2', () => {
            attachSignin(window.gapi.auth2.getAuthInstance());
          }),
        );
      }
    }
  }, [onGoogleAuth]);

  return { googleSignOut };
};

export default useGoogle;
