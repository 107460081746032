/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const persist = createSlice({
  name: 'persist',
  initialState: { lastShown: null },
  reducers: {
    setLastShown(state, action) {
      state.lastShown = action.payload;
    },
  },
});

export const { setLastShown } = persist.actions;

export default persist.reducer;

export const selectLastShown = (state) => state.persist.lastShown;
