import { createSelector, createSlice } from '@reduxjs/toolkit';

import API from '../constants/API';
import axios from '../constants/axios';

const login = createSlice({
  name: 'login',
  initialState: null,
  reducers: {
    setThisUser(state, action) {
      return action.payload;
    },
  },
});

export const { setThisUser } = login.actions;

export default login.reducer;

export const fetchThisUser =
  (recovered = false) =>
  async (dispatch) => {
    try {
      const { data } = await axios.post(API.getUser);
      dispatch(setThisUser({ ...data.data, recovered }));
      if (window) {
        window.ReactNativeWebView?.postMessage(
          JSON.stringify({ type: 'userId', data: data.data.id }),
        );
      }
    } catch (err) {
      dispatch(setThisUser(null));
      if (window) {
        window.ReactNativeWebView?.postMessage(
          JSON.stringify({ type: 'userId', data: null }),
        );
      }
    }
  };

export const selectUser = (state) => state.login;
export const selectIsTutor = createSelector(selectUser, (user) =>
  user ? user.personal.type === 'tutor' : false,
);
export const selectIsStudent = createSelector(selectUser, (user) =>
  user ? user.personal.type === 'student' : false,
);
export const selectMyId = createSelector(selectUser, (user) =>
  user ? user.id : null,
);
export const selectHoneyPoint = (state) =>
  state.login ? state.login.personal.honeyPoint : null;
export const selectIsPartner = (state) =>
  state.login ? !!state.login.partner : null;
