import './SnackBar.scss';

import React from 'react';
import { Trans } from 'react-i18next';
import ReactModal from 'react-modal';
import { useSelector } from 'react-redux';

const SnackBar = () => {
  const saveDate = useSelector((state) => state.common.saveDate);
  const now = new Date();

  if (!saveDate) {
    return null;
  }

  return (
    <ReactModal
      isOpen={!!saveDate}
      overlayClassName="snackbar-overlay"
      bodyOpenClassName="snackbar-open"
      className="snackbar"
      closeTimeoutMS={75}
    >
      <div className="checkmark draw" />
      <p>
        {typeof saveDate === 'string' ? (
          saveDate
        ) : (
          <Trans
            i18nKey="common:saved"
            values={{
              time: now?.toLocaleTimeString(undefined, {
                hour: '2-digit',
                minute: '2-digit',
                hour12: false,
              }),
            }}
          />
        )}
      </p>
    </ReactModal>
  );
};

export default SnackBar;
