import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { onError, selectError } from 'shared/store/common';

import useAlertErrorModal from './modals/AlertErrorModal';

const Error = () => {
  const alertErrorModal = useAlertErrorModal();
  const error = useSelector(selectError);
  const dispatch = useDispatch();

  useEffect(() => {
    if (error) {
      alertErrorModal({ message: error })
        .then(() => {
          dispatch(onError(null));
        })
        .catch(() => {
          dispatch(onError(null));
        });
    }
  }, [error]);

  return null;
};

export default Error;
