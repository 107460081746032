import React from 'react';
import ReactModal from 'react-modal';
import { useSelector } from 'react-redux';

import { selectIsLoading } from 'shared/store/common';

import Loader from './ui/Loader';

const LoadingScreen = () => {
  const isLoading = useSelector(selectIsLoading);

  if (!isLoading) {
    return null;
  }

  return (
    <ReactModal
      isOpen={isLoading}
      overlayClassName="modal-overlay"
      className="modal-content fullscreen modal-loading"
    >
      <Loader />
    </ReactModal>
  );
};

export default LoadingScreen;
