/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';
import React from 'react';

import cx from 'utils/cx';

const Star = ({
  fillId,
  changeRating,
  hoverOverStar,
  unHoverOverStar,
  isStarred,
  isPartiallyFullStar,
  isHovered,
  hoverMode,
  isCurrentHoveredStar,
  isFirstStar,
  isLastStar,
  starDimension,
  starSpacing,
  color,
}) => {
  const colors = { primary: '#ffdf00', secondary: '#ffbc00' };
  const starEmptyColor = '#fff';
  const starHoverColor = colors[color];
  const starRatedColor = colors[color];

  const starContainerStyle = {
    position: 'relative',
    display: 'inline-block',
    width: starDimension,
    height: starDimension,
    verticalAlign: 'middle',
    paddingLeft: isFirstStar ? undefined : `${starSpacing}px`,
    paddingRight: isLastStar ? undefined : `${starSpacing}px`,
    cursor: changeRating ? 'pointer' : undefined,
    outline: 'none',
  };

  const starSvgStyle = {
    width: `${starDimension}px`,
    height: `${starDimension}px`,
    transition: 'transform .2s ease-in-out',
    transform: isCurrentHoveredStar ? 'scale(1.1)' : undefined,
  };

  const pathStyle = {
    fill: hoverMode
      ? isHovered
        ? starHoverColor
        : starEmptyColor
      : isPartiallyFullStar
      ? `url('#${fillId}')`
      : isStarred
      ? starRatedColor
      : starEmptyColor,
    transition: 'fill .2s ease-in-out',
  };

  const starClasses = cx({
    'widget-svg': true,
    'multi-widget-selected': isPartiallyFullStar,
    hovered: isHovered,
    'current-hovered': isCurrentHoveredStar,
  });

  return (
    <div
      className="star-container"
      style={starContainerStyle}
      onMouseEnter={hoverOverStar}
      onMouseLeave={unHoverOverStar}
      onClick={changeRating}
      onKeyDown={changeRating}
      aria-label="star"
      role="button"
      tabIndex={0}
    >
      <svg
        viewBox={`0 0 ${starDimension} ${starDimension}`}
        className={starClasses}
        style={starSvgStyle}
      >
        <path
          className="star"
          style={pathStyle}
          d="M8 1L10.163 5.27865L15 5.96898L11.5 9.29758L12.326 14L8 11.7787L3.674 14L4.5 9.29758L1 5.96898L5.837 5.27865L8 1Z"
          stroke={starHoverColor}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

Star.propTypes = {
  fillId: PropTypes.string.isRequired,
  changeRating: PropTypes.func,
  hoverOverStar: PropTypes.func,
  unHoverOverStar: PropTypes.func,
  isStarred: PropTypes.bool.isRequired,
  isPartiallyFullStar: PropTypes.bool.isRequired,
  isHovered: PropTypes.bool.isRequired,
  hoverMode: PropTypes.bool.isRequired,
  isCurrentHoveredStar: PropTypes.bool.isRequired,
  isFirstStar: PropTypes.bool.isRequired,
  isLastStar: PropTypes.bool.isRequired,
  starDimension: PropTypes.number.isRequired,
  starSpacing: PropTypes.number.isRequired,
  color: PropTypes.string,
};

Star.defaultProps = {
  changeRating: null,
  hoverOverStar: null,
  unHoverOverStar: null,
  color: 'primary',
};

export default Star;
