import { combineReducers, configureStore } from '@reduxjs/toolkit';
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';

import common from './common';
import login from './login';
import curriculums from './curriculums';
import locations from './locations';
import schools from './schools';
import chat from './chat';
import persist from './persist';

const reducer = combineReducers({
  common,
  login,
  ...curriculums,
  locations,
  schools,
  chat,
  persist,
});

const persistConfig = (storage) => ({
  key: 'root',
  version: 1,
  storage,
  whitelist: [
    'login',
    'subjects',
    'curriculums',
    'locations',
    'schools',
    'persist',
  ],
});

const persistedReducer = (storage) =>
  persistReducer(persistConfig(storage), reducer);

export default (storage) =>
  configureStore({
    reducer: persistedReducer(storage),
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
        immutableCheck: false,
      }),
  });
