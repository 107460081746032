import './Footer.scss';

import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import {
  appStoreLink,
  companyStory,
  googlePlayLink,
  isDev,
  tutorhiveEmail,
  tutorhiveFaq,
  tutorhiveKakao,
  tutorhiveTerms,
} from 'shared/utils/utils';

import { ReactComponent as AppStoreIcon } from 'assets/icons/appstore.svg';
import { ReactComponent as ChatIcon } from 'assets/icons/chat.svg';
import { ReactComponent as DropDownIcon } from 'assets/icons/dropdown.svg';
import { ReactComponent as GooglePlayIcon } from 'assets/icons/googleplay.svg';
import { ReactComponent as MailIcon } from 'assets/icons/mail.svg';
import { isWebApp } from 'utils/utils';
import ExternalLink from './ui/ExternalLink';

const Footer = () => {
  const { t } = useTranslation('footer');
  const location = useLocation();

  if (
    location.pathname.includes('chat') ||
    location.pathname.includes('newsletter') ||
    ['/ckeditor'].includes(location.pathname)
  ) {
    return null;
  }

  const getNavList = () => (
    <ul className="row nav-list">
      <li>
        <ExternalLink href={companyStory}>회사소개</ExternalLink>
      </li>
      <li>
        <ExternalLink href={tutorhiveTerms}>{t('terms')}</ExternalLink>
      </li>
      <li>
        <ExternalLink href={tutorhiveFaq}>{t('faq')}</ExternalLink>
      </li>
      {isDev && (
        <li>
          <a>{t('korean')}</a>
          <i>🇰🇷</i>
          <DropDownIcon />
        </li>
      )}
    </ul>
  );

  const getCsLinks = () => (
    <ul className="row cs-links">
      <li>
        <ExternalLink href={tutorhiveKakao}>
          <ChatIcon />
          카톡채널
        </ExternalLink>
      </li>
      <li>
        <ExternalLink href={`mailto:${tutorhiveEmail}`}>
          <MailIcon />
          help@tutorhive.kr
        </ExternalLink>
      </li>
    </ul>
  );

  const getStoreList = () =>
    !isWebApp && (
      <ul className="row store-list">
        <li>
          <ExternalLink href={appStoreLink} aria-label="App Store">
            <AppStoreIcon />
          </ExternalLink>
        </li>
        <li>
          <ExternalLink href={googlePlayLink} aria-label="Google Play">
            <GooglePlayIcon />
          </ExternalLink>
        </li>
      </ul>
    );

  return (
    <footer className="footer-wrapper">
      {getNavList()}
      {getCsLinks()}
      {getStoreList()}
      <div className="footor-bottom">
        <p>{t('address')}</p>
        <p>Copyright © GuruMe All Rights Reserved</p>
      </div>
    </footer>
  );
};

export default memo(Footer);
