import './Modal.scss';

import PropTypes from 'prop-types';
import React from 'react';
import ReactModal from 'react-modal';

import { ReactComponent as ErrorIcon } from 'assets/icons/error.svg';
import { ReactComponent as SuccessIcon } from 'assets/icons/success.svg';
import { ReactComponent as TimesCircleIcon } from 'assets/icons/times-circle.svg';
import cx from 'utils/cx';
import IconButton from './IconButton';

ReactModal.setAppElement('body');

const Modal = ({
  fullscreen,
  isOpen,
  onRequestClose,
  type,
  message,
  className,
  children,
  hideClose,
  ...rest
}) => {
  if (!isOpen) {
    return null;
  }

  const getCloseButton = () => (
    <IconButton
      className="close-modal"
      onClick={() => onRequestClose(null)}
      aria-label="close"
    >
      <TimesCircleIcon />
    </IconButton>
  );

  const getAlertIcon = () => (
    <div className="modal-icon">
      {type === 'success' ? <SuccessIcon /> : <ErrorIcon />}
    </div>
  );

  const getModalMessage = () => <p className="modal-message">{message}</p>;

  return (
    <ReactModal
      isOpen={isOpen}
      overlayClassName="modal-overlay"
      className={cx('modal-content', { fullscreen }, className)}
      bodyOpenClassName="modal-open"
      onRequestClose={() => onRequestClose(null)}
      closeTimeoutMS={75}
      {...rest}
    >
      {!fullscreen && !hideClose && getCloseButton()}
      {type && getAlertIcon()}
      {message && getModalMessage()}
      {children}
    </ReactModal>
  );
};

Modal.propTypes = {
  fullscreen: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  type: PropTypes.string,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  className: PropTypes.string,
  hideClose: PropTypes.bool,
  children: PropTypes.node,
};

Modal.defaultProps = {
  fullscreen: false,
  type: '',
  message: '',
  className: null,
  hideClose: false,
  children: null,
};

export default Modal;
