import './App.scss';
import 'react-multi-carousel/lib/styles.css';

import React, { Suspense, useEffect, useMemo, useState } from 'react';
import { isIOS } from 'react-device-detect';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, useLocation } from 'react-router-dom';

import useAppleApi from 'shared/hooks/login/useAppleAPI';
import useGoogleAPI from 'shared/hooks/login/useGoogleAPI';
import useUser from 'shared/hooks/useUser';
import { selectIsLoading } from 'shared/store/common';
import { fetchCurriculums } from 'shared/store/curriculums';
import { fetchLocations } from 'shared/store/locations';
import { fetchThisUser } from 'shared/store/login';
import { fetchHighschools, fetchUniversities } from 'shared/store/schools';
import { isDev, serverURL } from 'shared/utils/utils';

import Error from 'components/Error';
import Footer from 'components/Footer';
import Header from 'components/Header';
import LoadingScreen from 'components/LoadingScreen';
import useChatMessageLimitModal from 'components/modals/ChatMessageLimitModal';
import Sidenav from 'components/Sidenav';
import SmartBanner from 'components/SmartBanner';
import SnackBar from 'components/ui/SnackBar';
import useScrollToTop from 'hooks/useScrollToTop';
import { isWebApp } from 'utils/utils';
import ProtectedRoute from './hocs/ProtectedRoute';
import Route from './hocs/Route';
import cx from './utils/cx';
import lazyWithRetry from './utils/lazyWithRetry';

const RequiredInfo = lazyWithRetry(() =>
  import('./pages/required-info/RequiredInfo'),
);
const Register = lazyWithRetry(() => import('./pages/register/Register'));
const Login = lazyWithRetry(() => import('./pages/login/Login'));
const ResetPassword = lazyWithRetry(() =>
  import('./pages/reset-password/ResetPassword'),
);
const Cs = lazyWithRetry(() => import('./pages/cs/Cs'));
const Students = lazyWithRetry(() => import('./pages/students/Students'));
const Tutors = lazyWithRetry(() => import('./pages/tutors/Tutors'));
const Main = lazyWithRetry(() => import('./pages/main/Main'));
const StudentProfile = lazyWithRetry(() =>
  import('./pages/student-profile/StudentProfile'),
);
const TutorProfile = lazyWithRetry(() =>
  import('./pages/tutor-profile/TutorProfile'),
);
const TutorEditProfile = lazyWithRetry(() =>
  import('./pages/tutor-edit-profile/TutorEditProfile'),
);
const TutorEditTuition = lazyWithRetry(() =>
  import('./pages/tutor-edit-tuition/TutorEditTuition'),
);
const TutorPreInterview = lazyWithRetry(() =>
  import('./pages/tutor-pre-interview/TutorPreInterview'),
);
const TutorPreInterviewCompleted = lazyWithRetry(() =>
  import('./pages/tutor-pre-interview-completed/TutorPreInterviewCompleted'),
);
const TutorPostInterviewCompleted = lazyWithRetry(() =>
  import('./pages/tutor-post-interview-completed/TutorPostInterviewCompleted'),
);
const TutorPostInterview = lazyWithRetry(() =>
  import('./pages/tutor-post-interview/TutorPostInterview'),
);
const StudentSignUp = lazyWithRetry(() =>
  import('./pages/student-sign-up/StudentSignUp'),
);
const StudentWelcome = lazyWithRetry(() =>
  import('./pages/student-welcome/StudentWelcome'),
);
const StudentEditProfile = lazyWithRetry(() =>
  import('./pages/student-edit-profile/StudentEditProfile'),
);
const StudentRequestTuition = lazyWithRetry(() =>
  import('./pages/student-request-tuition/StudentRequestTuition'),
);
const TutorReview = lazyWithRetry(() =>
  import('./pages/tutor-review/TutorReview'),
);
const InvoiceIssue = lazyWithRetry(() =>
  import('./pages/invoice-issue/InvoiceIssue'),
);
const InvoiceReview = lazyWithRetry(() =>
  import('./pages/invoice-review/InvoiceReview'),
);
const Payment = lazyWithRetry(() => import('./pages/payment/Payment'));
const CreditBalance = lazyWithRetry(() =>
  import('./pages/credit-balance/CreditBalance'),
);
const MyPage = lazyWithRetry(() => import('./pages/my-page/MyPage'));
const ClassReport = lazyWithRetry(() =>
  import('./pages/class-report/ClassReport'),
);
const Exit = lazyWithRetry(() => import('./pages/exit/Exit'));
const Chat = lazyWithRetry(() => import('./pages/chat/Chat'));
const ChatRoom = lazyWithRetry(() => import('./pages/chat-room/ChatRoom'));
const DiscussionPost = lazyWithRetry(() =>
  import('./pages/discussion-post/DiscussionPost'),
);
const Discussion = lazyWithRetry(() => import('./pages/discussion/Discussion'));
const DiscussionShow = lazyWithRetry(() =>
  import('./pages/discussion-show/DiscussionShow'),
);
const NotFound = lazyWithRetry(() => import('./pages/not-found/NotFound'));
const Activate = lazyWithRetry(() => import('./pages/activate/Activate'));
const ExitCompleted = lazyWithRetry(() =>
  import('./pages/exit/components/ExitCompleted'),
);
const WelcomeBack = lazyWithRetry(() =>
  import('./pages/welcome-back/WelcomeBack'),
);
const Newsletter = lazyWithRetry(() => import('./pages/newsletter/Newsletter'));
const PayScreen = lazyWithRetry(() => import('./webview/PayScreen'));
const CkEditorScreen = lazyWithRetry(() => import('./webview/CkEditorScreen'));
const Stories = lazyWithRetry(() => import('./pages/stories/Stories'));
const StoryPayment = lazyWithRetry(() =>
  import('./pages/story-payment/StoryPayment'),
);
const StoryShow = lazyWithRetry(() => import('./pages/story-show/StoryShow'));
const StoryNew = lazyWithRetry(() => import('./pages/story-new/StoryNew'));
const StoryNewCompleted = lazyWithRetry(() =>
  import('./pages/story-new-completed/StoryNewCompleted'),
);

const PartnerRegistration = lazyWithRetry(() =>
  import('./pages/partner-registration/PartnerRegistration'),
);
const PartnerProfile = lazyWithRetry(() =>
  import('./pages/partner-profile/PartnerProfile'),
);
const Partners = lazyWithRetry(() => import('./pages/partners/Partners'));

const HowItWorks = lazyWithRetry(() =>
  import('./pages/how-it-works/HowItWorks'),
);

const useFetchMetadataOnInit = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (location.pathname !== '/pay' && location.pathname !== '/ckeditor') {
      dispatch(fetchThisUser());
      dispatch(fetchLocations());
      dispatch(fetchCurriculums());
      dispatch(fetchUniversities());
      dispatch(fetchHighschools());
    }
  }, []);
};

const useToggleNav = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);

  const toggleNav = () => {
    setIsNavOpen((isOpen) => !isOpen);
  };
  const closeNav = () => {
    setIsNavOpen(false);
  };

  return { isNavOpen, toggleNav, closeNav };
};

const useOgTag = () => {
  const title = '글로벌 교육 플랫폼 튜터하이브';
  const location = useLocation();

  const suffix = useMemo(
    () => (!location.pathname.includes('/tutors/') ? title : '튜터하이브'),
    [location],
  );

  return {
    titleTemplate: `${isDev ? 'Local ' : ''}%s | ${suffix}`,
    defaulTitle: `${isDev ? 'Local ' : ''}${title}`,
  };
};

const useWebviewSnsLogin = () => {
  const { googleRegisterMutation, googleLoginMutation } = useGoogleAPI();
  const { appleRegisterMutation, appleLoginMutation } = useAppleApi();

  useEffect(() => {
    if (isWebApp) {
      const handleMessage = ({ data }) => {
        const parsedMessage = JSON.parse(data);
        switch (parsedMessage.type) {
          case 'googleAuth':
            if (parsedMessage.data.type) {
              googleRegisterMutation.mutate(parsedMessage.data);
            } else {
              googleLoginMutation.mutate(parsedMessage.data);
            }
            break;
          case 'appleAuth':
            if (parsedMessage.data.type) {
              appleRegisterMutation.mutate(parsedMessage.data);
            } else {
              appleLoginMutation.mutate(parsedMessage.data);
            }
            break;
          default:
            break;
        }
      };
      if (isIOS) {
        window.addEventListener('message', handleMessage);
      } else {
        document.addEventListener('message', handleMessage);
      }
    }
  }, []);
};

const App = () => {
  const { t } = useTranslation('pages');
  const location = useLocation();
  const { thisUser, isTutor, isStudent } = useUser();
  const isLoading = useSelector(selectIsLoading);
  useScrollToTop();
  useFetchMetadataOnInit();
  const { isNavOpen, toggleNav, closeNav } = useToggleNav();
  const { titleTemplate, defaultTitle } = useOgTag();
  useWebviewSnsLogin();

  return (
    <Suspense fallback={<LoadingScreen />}>
      <Helmet titleTemplate={titleTemplate} defaultTitle={defaultTitle}>
        <meta
          name="description"
          content="전세계 명문대 출신의 인재들과 함께 국제교육과정 과외, 서비스를 제공합니다"
        />
        {isIOS && (
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
          />
        )}
      </Helmet>
      <Header
        isNavOpen={isNavOpen}
        toggleNav={toggleNav}
        isMain={location.pathname === '/'}
      />
      <div
        className={cx('tutor-hive', {
          'is-main': location.pathname === '/',
          'is-auth': thisUser,
          'no-scroll':
            location.pathname.includes('/chat/') ||
            location.pathname === '/credit-balance',
        })}
      >
        <Sidenav isOpen={isNavOpen} onClose={closeNav} />
        <Switch>
          <Route
            path="/admin"
            component={(e) => {
              window.location.href =
                serverURL + e.location.pathname.substring(1);
              return null;
            }}
          />
          <Route path="/newsletter/:id">
            <Newsletter />
          </Route>
          <ProtectedRoute path="/required-info" type="private">
            <RequiredInfo />
          </ProtectedRoute>
          <ProtectedRoute path="/login" type="guest" title={t('login')}>
            <Login />
          </ProtectedRoute>
          <ProtectedRoute path="/register" type="guest" title={t('register')}>
            <Register />
          </ProtectedRoute>
          <ProtectedRoute path="/login-test" type="guest" title={t('login')}>
            <Login test />
          </ProtectedRoute>
          <ProtectedRoute
            path="/register-test"
            type="guest"
            title={t('register')}
          >
            <Register test />
          </ProtectedRoute>
          <ProtectedRoute
            path="/reset-password"
            type="guest"
            title={t('resetPassword')}
          >
            <ResetPassword />
          </ProtectedRoute>
          <Route path="/cs" title={t('cscenter')}>
            <Cs />
          </Route>
          <ProtectedRoute
            path="/register-tutor"
            condition={isTutor && thisUser?.personal?.registrationStep === 1}
            title={t('tutorRegister')}
          >
            <TutorPreInterview />
          </ProtectedRoute>
          <ProtectedRoute
            path="/confirm-register-tutor"
            condition={
              isTutor &&
              (thisUser?.personal?.registrationStep === 2 ||
                thisUser?.personal?.registrationStep === 5)
            }
            title={t('tutorRegister')}
          >
            <TutorPreInterviewCompleted />
          </ProtectedRoute>
          <ProtectedRoute
            path="/confirm-register-story"
            type="tutor"
            title={t('tutorApplicationRegister')}
          >
            <TutorPostInterviewCompleted />
          </ProtectedRoute>
          <ProtectedRoute
            path="/tutors/:id/register"
            condition={isTutor && thisUser?.personal?.registrationStep === 3}
            onlyMine
            title={t('tutorApplicationRegister')}
          >
            <TutorPostInterview />
          </ProtectedRoute>
          <ProtectedRoute
            path="/tutors/:id/edit-tuition"
            type="tutor"
            onlyMine
            title={t('tuitionProfile')}
          >
            <TutorEditTuition />
          </ProtectedRoute>
          <ProtectedRoute
            path="/tutors/:id/edit-profile"
            type="tutor"
            onlyMine
            title={t('myProfile')}
          >
            <TutorEditProfile />
          </ProtectedRoute>
          <Route path="/tutors/:id">
            <TutorProfile />
          </Route>
          <Route path="/tutors" title={t('searchTutors')}>
            <Tutors />
          </Route>
          <ProtectedRoute
            path="/register-student"
            condition={isStudent && thisUser?.personal?.registrationStep === 1}
            title={t('studentRegister')}
          >
            <StudentSignUp />
          </ProtectedRoute>
          <ProtectedRoute
            path="/welcome-student"
            condition={isStudent && thisUser?.personal?.registrationStep === 2}
            title={t('studentRegister')}
          >
            <StudentWelcome />
          </ProtectedRoute>
          <ProtectedRoute
            path="/students/:id/request-tuition"
            type="student"
            onlyMine
            title={t('tuitionRequest')}
          >
            <StudentRequestTuition />
          </ProtectedRoute>
          <ProtectedRoute
            path="/students/:id/edit-profile"
            type="student"
            onlyMine
            title={t('myProfile')}
          >
            <StudentEditProfile />
          </ProtectedRoute>
          <Route path="/students/:id">
            <StudentProfile />
          </Route>
          <Route path="/students" title={t('searchStudents')}>
            <Students />
          </Route>
          <ProtectedRoute path="/chat/:id" title={t('chat')}>
            <ChatRoom />
          </ProtectedRoute>
          <ProtectedRoute path="/chat" title={t('chat')}>
            <Chat />
          </ProtectedRoute>
          <ProtectedRoute path="/invoice/:id/edit" title={t('invoice')}>
            <InvoiceIssue />
          </ProtectedRoute>
          <ProtectedRoute path="/invoice/:id" title={t('invoice')}>
            <InvoiceReview />
          </ProtectedRoute>
          <ProtectedRoute path="/invoice" title={t('invoice')}>
            <InvoiceIssue />
          </ProtectedRoute>
          <ProtectedRoute path="/payment/:id" title={t('payment')}>
            <Payment />
          </ProtectedRoute>
          <ProtectedRoute path="/credit-balance" title={t('honeyPoint')}>
            <CreditBalance />
          </ProtectedRoute>
          <ProtectedRoute path="/class/:id/report/new" title={t('report')}>
            <ClassReport write />
          </ProtectedRoute>
          <ProtectedRoute path="/class/:id/review" title={t('review')}>
            <TutorReview />
          </ProtectedRoute>
          <ProtectedRoute path="/class/:id/report" title={t('report')}>
            <ClassReport />
          </ProtectedRoute>
          <Route path="/exit/completed" title={t('exit')}>
            <ExitCompleted />
          </Route>
          <ProtectedRoute path="/exit" title={t('exit')}>
            <Exit />
          </ProtectedRoute>
          <ProtectedRoute path="/welcome-back" title={t('welcomeBack')}>
            <WelcomeBack />
          </ProtectedRoute>
          <ProtectedRoute path="/mypage">
            <MyPage />
          </ProtectedRoute>
          <ProtectedRoute path="/discussions/new" title={t('community')}>
            <DiscussionPost />
          </ProtectedRoute>
          <ProtectedRoute path="/discussions/:id/edit" title={t('community')}>
            <DiscussionPost />
          </ProtectedRoute>
          <Route path="/discussions/:id">
            <DiscussionShow />
          </Route>
          <Route path="/discussions" title={t('community')}>
            <Discussion />
          </Route>
          <Route path="/activate">
            <Activate />
          </Route>
          <Route path="/pay">
            <PayScreen />
          </Route>
          <Route path="/ckeditor">
            <CkEditorScreen />
          </Route>
          <ProtectedRoute
            type="tutor"
            path="/stories/new/completed"
            title={t('story')}
          >
            <StoryNewCompleted />
          </ProtectedRoute>
          <ProtectedRoute type="tutor" path="/stories/new" title={t('story')}>
            <StoryNew />
          </ProtectedRoute>
          <ProtectedRoute
            type="student"
            path="/stories/:id/payment"
            title={t('story')}
          >
            <StoryPayment />
          </ProtectedRoute>
          <Route path="/stories/:id">
            <StoryShow />
          </Route>
          <Route path="/stories" title={t('story')}>
            <Stories />
          </Route>
          <ProtectedRoute type="tutor" path="/partners/new" title="파트너">
            <PartnerRegistration />
          </ProtectedRoute>
          <ProtectedRoute type="tutor" path="/partners/edit" title="파트너">
            <PartnerRegistration />
          </ProtectedRoute>
          <Route path="/partners/:id" title="파트너">
            <PartnerProfile />
          </Route>
          <Route path="/partners" title="파트너">
            <Partners />
          </Route>
          <ProtectedRoute path="/" exact type="main">
            <Main />
          </ProtectedRoute>
          <Route path="/how-it-works">
            <HowItWorks />
          </Route>
          <Route>
            <NotFound />
          </Route>
        </Switch>
        {!isLoading && <Footer />}
      </div>
      <LoadingScreen />
      <SnackBar />
      <SmartBanner />
      <Error />
    </Suspense>
  );
};

export default App;
