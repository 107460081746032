import PropTypes from 'prop-types';
import React from 'react';
import { Route as RouterRoute } from 'react-router-dom';

import Seo from 'components/Seo';

const Route = ({ title, children, render, ...rest }) => (
  <RouterRoute
    {...rest}
    render={(props) => (
      <>
        {title && <Seo title={title} />}
        {render ? render({ children, ...props }) : children}
      </>
    )}
  />
);

Route.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  render: PropTypes.func,
};

Route.defaultProps = {
  title: '',
  children: null,
  render: null,
};

export default Route;
