import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import groupby from 'lodash.groupby';

import { normalize, schema } from 'normalizr';
import API from '../constants/API';
import axios from '../constants/axios';

const subjectEntity = new schema.Entity('subjects', {});

const curriculumEntity = new schema.Entity('curriculums', {
  subjects: [subjectEntity],
});

export const fetchCurriculums = createAsyncThunk(
  'common/fetchCurriculums',
  async () => {
    const { data } = await axios.get(API.getCurriculums);
    const subjectsByCurriculum = Object.entries(
      groupby(data, 'curriculum'),
    ).map(([curriculum, subjects]) => ({
      id: curriculum,
      kr: subjects[0].curriculum_kr,
      subjects,
    }));
    const normalized = normalize(subjectsByCurriculum, [curriculumEntity]);
    return normalized.entities;
  },
);

const curriculumsAdapter = createEntityAdapter();

const curriculums = createSlice({
  name: 'curriculums',
  initialState: curriculumsAdapter.getInitialState(),
  extraReducers: {
    [fetchCurriculums.fulfilled]: (state, action) => {
      curriculumsAdapter.setAll(state, action.payload.curriculums);
    },
  },
});

const subjectsAdapter = createEntityAdapter();

const subjects = createSlice({
  name: 'subjects',
  initialState: subjectsAdapter.getInitialState(),
  extraReducers: {
    [fetchCurriculums.fulfilled]: (state, action) => {
      subjectsAdapter.setAll(state, action.payload.subjects);
    },
  },
});

export default { curriculums: curriculums.reducer, subjects: subjects.reducer };

export const {
  selectAll: selectAllCurriculums,
  selectEntities: selectCurriculumsEntities,
  selectIds: selectCurriculumsIds,
} = curriculumsAdapter.getSelectors((state) => state.curriculums);

export const {
  selectAll: selectAllSubjects,
  selectEntities: selectSubjectsEntities,
} = subjectsAdapter.getSelectors((state) => state.subjects);

export const subjectOptionsSelector = createSelector(
  selectAllSubjects,
  (subjects) =>
    subjects.map((subject) => ({
      value: subject.id,
      label: `${subject.curriculum} - ${subject.subject}`,
    })),
);

export const curriculumsMultiLanguageOptionsSelector = createSelector(
  selectAllCurriculums,
  (curriculums) =>
    curriculums.map((curriculum) => ({
      value: curriculum.id,
      label: curriculum.kr
        ? `${curriculum.id.replace(/ \(.*\)/g, '')} · ${curriculum.kr}`
        : curriculum.id,
    })),
);
