import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { onError, onLoading } from '../store/common';

const useSimpleLoading = () => {
  const dispatch = useDispatch();

  const onSimpleLoading = useCallback(() => dispatch(onLoading(true)), []);
  const onLoadingEnd = useCallback(() => dispatch(onLoading(false)), []);
  const onSimpleError = useCallback((err) => {
    dispatch(onError(err.message));
  }, []);

  return { onLoading: onSimpleLoading, onLoadingEnd, onError: onSimpleError };
};

export default useSimpleLoading;
