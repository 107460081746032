/* eslint-disable no-underscore-dangle */
import isEmpty from 'lodash.isempty';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SendBird from 'sendbird';
import SendBirdSyncManager from 'sendbird-syncmanager';
import API from 'shared/constants/API';
import axios from 'shared/constants/axios';

import useMessageSent from 'shared/hooks/chat-room/useMessageSent';
import useUser from 'shared/hooks/useUser';
import {
  onAddPendingMessage,
  onInsertChannels,
  onInsertMessages,
  onResetChannels,
  onUpdateChannels,
  onUpdateMessages,
  selectChannels,
  selectDirection,
  selectMessages,
  selectPendingMessage,
} from 'shared/store/chat';
import {
  onChatConnection,
  onLoading,
  selectChatConnected,
} from 'shared/store/common';
import { isDev, isTestServer } from 'shared/utils/utils';
import useChatMessageLimitModal from 'components/modals/ChatMessageLimitModal';

const testAppId = process.env.REACT_APP_TEST_CHAT_ID;
const liveAppId = process.env.REACT_APP_LIVE_CHAT_ID;
const sb = new SendBird({
  appId: !isDev && !isTestServer ? liveAppId : testAppId,
});

const useChat = () => {
  const { myId, thisUser, isTutor } = useUser();
  const dispatch = useDispatch();
  const messageSentMutation = useMessageSent();
  const chatConnected = useSelector(selectChatConnected);
  const channels = useSelector(selectChannels);
  const messages = useSelector(selectMessages);
  const pendingMessage = useSelector(selectPendingMessage);
  const direction = useSelector(selectDirection);
  const chatMessageLimitModal = useChatMessageLimitModal();

  const createConnectionHandler = (sb) => {
    const connectionHandler = new sb.ConnectionHandler();
    connectionHandler.onReconnectSucceeded = () => {
      const manager = SendBirdSyncManager.getInstance();
      manager.resumeSync();
    };
    connectionHandler.onReconnectFailed = () => {
      sb.reconnect();
    };
  };

  const updateUser = async () => {
    const sb = SendBird.getInstance();
    if (!sb.isSessionOpened) {
      // eslint-disable-next-line no-use-before-define
      await connection();
    } else {
      sb.updateCurrentUserInfo(thisUser?.nickname, thisUser?.picture || '');
      let metaData = null;
      if (isTutor) {
        const { degreeType, schoolId } =
          thisUser.schoolList.find(
            ({ isRepresentative }) => isRepresentative,
          ) ||
          thisUser.schoolList?.[0] ||
          {};
        metaData = {
          university: JSON.stringify({ degreeType, schoolId }),
        };
      } else {
        metaData = { subjects: JSON.stringify(thisUser.subjects) };
      }

      if (isEmpty(sb.currentUser.metaData)) {
        sb.currentUser.createMetaData(metaData);
      } else {
        sb.currentUser.updateMetaData(metaData);
      }
    }
  };

  const connection = useCallback(
    () =>
      new Promise((resolve, reject) => {
        if ((sb.isSessionOpened && sb.currentUser) || !thisUser) {
          resolve(sb);
          return;
        }

        const options = new SendBirdSyncManager.Options();
        options.messageCollectionCapacity = 2000;
        options.messageResendPolicy = 'automatic';
        options.automaticMessageResendRetryCount = 5;
        options.maxFailedMessageCountPerChannel = 50;
        options.failedMessageRetentionDays = 7;

        SendBirdSyncManager.sendBird = sb;
        SendBirdSyncManager.setup(`${myId}`, options)
          .then(() => {
            sb.connect(`${myId}`, (user, error) => {
              if (!error) {
                createConnectionHandler(sb);
                updateUser();
                resolve(sb);
                dispatch(onChatConnection(true));
                // The user is connected to Sendbird server.
              } else {
                console.log(error);
                reject(error);
                dispatch(onLoading(false));
              }
            });
          })
          .catch((err) => {
            reject(err);
            // Failed to initialize.
          });
      }),
    [thisUser],
  );

  const disconnect = useCallback(() => {
    SendBirdSyncManager.getInstance()?.reset();
    sb?.disconnect(() => {
      dispatch(onChatConnection(false));
    });
  }, []);

  const sendMessage = useCallback(async (collection, message, reply) => {
    if (!collection) {
      return;
    }

    const sb = await connection();
    const { channel } = collection;

    const params = new sb.UserMessageParams();
    params.message = message;
    if (reply) {
      params.parentMessageId = reply.messageId;
    }
    const receiver = +channel.members.find((m) => +m.userId !== myId).userId;

    if (!channel.lastMessage) {
      try {
        await axios.post(API.canSendMessage, {
          sender: myId,
          receiver,
        });
      } catch (e) {
        console.log(e.message === 'Message exceeds limit');
        chatMessageLimitModal();
        return;
      }
    }

    messageSentMutation.mutate({
      sender: myId,
      receiver,
      isFirstMessage: !isTutor && !channel.lastMessage,
      messageContent: message,
    });

    const pendingMessage = channel.sendUserMessage(params, (message, error) => {
      collection?.handleSendMessageResponse?.(error, message);
    });

    dispatch(
      onAddPendingMessage({
        ...pendingMessage,
        parentMessageText: reply?.message || '',
      }),
    );
    collection?.appendMessage?.(pendingMessage);
  }, []);

  const sendMessageTo = useCallback(
    async (userId, message, reply) => {
      const sb = await connection();
      const params = new sb.GroupChannelParams();
      params.addUserIds([`${userId}`, `${myId}`]);
      params.isDistinct = true;

      sb.GroupChannel.createChannel(params, (groupChannel, error) => {
        if (!error) {
          sendMessage({ channel: groupChannel }, message, reply);
        }
      });
    },
    [myId],
  );

  const sendImage = useCallback(async (collection, file) => {
    if (!collection) {
      return;
    }

    if (file) {
      if (!collection.channel.lastMessage) {
        const receiver = +collection.channel.members.find(
          (m) => +m.userId !== myId,
        ).userId;
        try {
          await axios.post(API.canSendMessage, {
            sender: myId,
            receiver,
          });
        } catch (e) {
          console.log(e.message === 'Message exceeds limit');
          chatMessageLimitModal();
          return;
        }
      }

      const fileMessageParams = new sb.FileMessageParams();
      fileMessageParams.file = file;
      fileMessageParams.fileName = file.name;
      const tempMessage = collection.channel.sendFileMessage(
        fileMessageParams,
        (message, err) => {
          if (!err) {
            if (message && collection) {
              collection.appendMessage(message);
            }
          }
        },
      );

      dispatch(onAddPendingMessage(tempMessage));
      collection?.appendMessage(tempMessage);
    }
  }, []);

  const pinMessage = useCallback((channel, { message, messageId }) => {
    channel.updateMetaData(
      { message, messageId: `${messageId}` },
      true,
      (response, error) => {},
    );
  }, []);

  const getPin = useCallback(
    (channel) =>
      new Promise((resolve, reject) => {
        channel.getMetaData(['message', 'messageId'], (response, error) => {
          if (!error) {
            resolve(response);
          }
          reject(error);
        });
      }),
    [],
  );

  const addPinHandler = useCallback(async (channel, callback) => {
    const sb = await connection();
    const channelHandler = new sb.ChannelHandler();
    channelHandler.onMetaDataUpdated = (channel, metaData) => {
      callback(metaData);
    };
    sb.addChannelHandler(channel.url, channelHandler);

    return async () => {
      sb.removeChannelHandler(channelHandler);
    };
  }, []);

  const createChannel = useCallback(
    async (id, callback = () => {}) => {
      const sb = await connection();
      const params = new sb.GroupChannelParams();
      params.addUserIds([`${id}`, `${myId}`]);
      params.isDistinct = true;

      sb.GroupChannel.createChannel(params, (channel, error) =>
        callback(channel, error),
      );
    },
    [myId],
  );

  const createChannelCollection = useCallback(async ({ favorite }) => {
    dispatch(onLoading(true));
    dispatch(onResetChannels());

    const sb = await connection();
    const query = sb.GroupChannel.createMyGroupChannelListQuery();
    query.limit = 100;
    if (favorite) {
      query.includeEmpty = true;
      query.userIdsIncludeFilter = favorite.map((id) => `${id}`);
    }

    const channelCollection = new SendBirdSyncManager.ChannelCollection(query);

    const collectionHandler =
      new SendBirdSyncManager.ChannelCollection.CollectionHandler();

    collectionHandler.onChannelEvent = (action, channels) => {
      switch (action) {
        case 'insert': {
          dispatch(onInsertChannels(channels));
          break;
        }
        case 'update': {
          dispatch(onUpdateChannels(channels));
          break;
        }
        default:
          break;
      }
    };

    channelCollection.setCollectionHandler(collectionHandler);
    channelCollection.fetch().then(() => {
      // to change
      setTimeout(() => dispatch(onLoading(false)), 500);
    });

    return channelCollection;
  }, []);

  const createMessageCollection = useCallback(
    async (id) => {
      const sb = await connection();
      const params = new sb.GroupChannelParams();
      params.addUserIds([`${id}`, `${myId}`]);
      params.isDistinct = true;

      return new Promise((resolve, reject) => {
        sb.GroupChannel.createChannel(params, (channel, error) => {
          if (error || channel.members?.length < 2) {
            resolve(null);
            return;
          }

          const collection = new SendBirdSyncManager.MessageCollection(channel);
          collection.limit = 50;

          const handler =
            new SendBirdSyncManager.MessageCollection.CollectionHandler();

          handler.onSucceededMessageEvent = (messages, action) => {
            switch (action) {
              case 'insert': {
                const unread = {};
                messages.forEach((message) => {
                  if (+message._sender.userId === myId) {
                    const unreadCount = channel.getReadReceipt(message);
                    unread[message.messageId] = unreadCount;
                  }
                });
                dispatch(
                  onInsertMessages(
                    messages.map((m) => ({
                      ...m,
                      unreadCount: unread[m.messageId],
                      url: m.url,
                    })),
                  ),
                );
                channel.markAsRead();
                dispatch(onLoading(false));
                break;
              }
              case 'update': {
                const unread = {};
                messages.forEach((message) => {
                  if (+message._sender.userId === myId) {
                    const unreadCount = channel.getReadReceipt(message);
                    unread[message.messageId] = unreadCount;
                  }
                });
                dispatch(
                  onUpdateMessages(
                    messages.map((m) => ({
                      ...m,
                      unreadCount: unread[m.messageId],
                      url: m.url,
                    })),
                  ),
                );
                break;
              }
              default:
                break;
            }
          };
          collection.setCollectionHandler(handler);
          collection.fetchSucceededMessages('prev');
          collection.fetchSucceededMessages('next');

          if (!channel.lastMessage) {
            dispatch(onLoading(false));
          }

          resolve(collection);
        });
      });
    },
    [myId],
  );

  return {
    sb,
    connection,
    disconnect,
    chatConnected,
    sendMessage,
    sendImage,
    sendMessageTo,
    channels,
    messages,
    pinMessage,
    getPin,
    pendingMessage,
    addPinHandler,
    createChannel,
    direction,
    createChannelCollection,
    createMessageCollection,
    updateUser,
  };
};

export default useChat;
