const sortOptions = [
  '/tutors',
  '/tutors?sorting=access',
  '/tutors?sorting=register',
  '/tutors?sorting=response',
];
export const tutorsLink =
  sortOptions[(new Date().getDay() + 1) % sortOptions.length];

export const isWebApp = navigator.userAgent.includes('TutorHive');
