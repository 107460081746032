/* eslint-disable react/button-has-type */
import './Button.scss';

import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';

import cx from 'utils/cx';

const Button = forwardRef(
  ({ className, color, type, children, ...rest }, ref) => (
    <button
      ref={ref}
      type={type}
      className={cx('btn', className, color)}
      {...rest}
    >
      {children}
    </button>
  ),
);

Button.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  children: PropTypes.node.isRequired,
};

Button.defaultProps = {
  className: null,
  color: 'primary',
  type: 'button',
};

export default Button;
