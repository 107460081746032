import PropTypes from 'prop-types';
import React from 'react';

const ExternalLink = ({ href, children, ...rest }) => {
  const onClick = (e) => {
    if (window.ReactNativeWebView) {
      e.preventDefault();
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          type: 'externalLink',
          data: href,
        }),
      );
    }
  };

  return (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      onClick={onClick}
      {...rest}
    >
      {children}
    </a>
  );
};

ExternalLink.propTypes = {
  href: PropTypes.string.isRequired,
};

export default ExternalLink;
