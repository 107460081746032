import './ChatMessageLimitModal.scss';

import PropTypes from 'prop-types';
import React from 'react';
import { createModal } from 'react-modal-promise';

import Modal from 'components/ui/Modal';

const ChatMessageLimitModal = ({ isOpen, onReject }) => {
  const closeModal = () => {
    onReject();
  };

  return (
    <Modal
      className="chat-message-limit-modal"
      isOpen={isOpen}
      onRequestClose={closeModal}
    >
      <p>
        쪽지를 보낼 수 있는 인원수는
        <br />
        <strong>하루 최대 10명</strong>입니다.
      </p>
      <p>
        당일 <strong>한도를 초과</strong>하였습니다.
      </p>
    </Modal>
  );
};

ChatMessageLimitModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onReject: PropTypes.func.isRequired,
};

const useChatMessageLimitModal = () => createModal(ChatMessageLimitModal);

export default useChatMessageLimitModal;
