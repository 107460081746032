import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';

import API from '../../constants/API';
import axios from '../../constants/axios';
import { fetchThisUser } from '../../store/login';
import useSimpleLoading from '../useSimpleLoading';

const useKakaoAPI = () => {
  const dispatch = useDispatch();
  const { onLoading, onLoadingEnd, onError } = useSimpleLoading(dispatch);

  const kakaoRegisterMutation = useMutation(
    ({ email, type, nickname, profileImg, phoneNumber }) => {
      return axios.post(API.registerKakao, {
        email,
        type,
        nickname,
        profileImg,
        phoneNumber,
      });
    },
    {
      onMutate: onLoading,
      onSettled: onLoadingEnd,
      onError,
      onSuccess: () => dispatch(fetchThisUser()),
    },
  );

  const kakaoLoginMutation = useMutation(
    ({ email, phoneNumber }) => {
      return axios.post(API.loginKakao, { email, phoneNumber });
    },
    {
      onMutate: onLoading,
      onSettled: onLoadingEnd,
      onError,
      onSuccess: () => dispatch(fetchThisUser()),
    },
  );

  return { kakaoRegisterMutation, kakaoLoginMutation };
};

export default useKakaoAPI;
