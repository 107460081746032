import axiosDefault from 'axios';
import { isDev, localServerURL, serialize, serverURL } from '../utils/utils';

const axios = axiosDefault.create({
  baseURL: isDev ? localServerURL : serverURL,
  withCredentials: true,
});

axios.interceptors.request.use((request) => {
  return { ...request, data: serialize(request.data) };
});

axios.interceptors.response.use((response) => {
  if (response) {
    if (response.data.status === 'fail') {
      const error = new Error(response.data.msg);
      error.status = 'fail';
      error.code = response.data.code;
      throw error;
    }
  }
  return response;
});

export default axios;
