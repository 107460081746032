import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { normalize, schema } from 'normalizr';

import groupby from 'lodash.groupby';
import API from '../constants/API';
import axios from '../constants/axios';

const cityEntity = new schema.Entity('cities');
const countryEntity = new schema.Entity('countries', { cities: [cityEntity] });

export const fetchLocations = createAsyncThunk(
  'locations/fetchLocations',
  async () => {
    const { data } = await axios.get(API.getLocations);

    const citiesByCountry = Object.entries(groupby(data, 'region_kr')).map(
      ([country, cities]) => ({ id: country, cities }),
    );

    const normalized = normalize(citiesByCountry, [countryEntity]);

    return normalized.entities;
  },
);

const countriesAdapter = createEntityAdapter({
  sortComparer: (a, b) => a.id.localeCompare(b.id),
});

const citiesAdapter = createEntityAdapter({
  sortComparer: (a, b) => a.city_kr.localeCompare(b.city_kr),
});

const initialState = {
  countries: countriesAdapter.getInitialState(),
  cities: citiesAdapter.getInitialState(),
};

const locations = createSlice({
  name: 'locations',
  initialState,
  extraReducers: {
    [fetchLocations.fulfilled]: (state, action) => {
      countriesAdapter.setAll(state.countries, action.payload.countries);
      citiesAdapter.setAll(state.cities, action.payload.cities);
    },
  },
});

export default locations.reducer;

export const {
  selectAll: selectAllCountries,
  selectEntities: selectCountriesEntities,
} = countriesAdapter.getSelectors((state) => state.locations.countries);

export const countriesOptionsSelector = createSelector(
  selectAllCountries,
  (countries) =>
    countries.map((country) => ({ value: country.id, label: country.id })),
);

export const {
  selectAll: selectAllCities,
  selectEntities: selectCitiesEntities,
} = citiesAdapter.getSelectors((state) => state.locations.cities);
