/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import './Sidenav.scss';

import PropTypes from 'prop-types';
import React, { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';

import useLogout from 'shared/hooks/login/useLogout';
import useUser from 'shared/hooks/useUser';
import { setThisUser } from 'shared/store/login';

import useChat from 'hooks/useChat';
import useGoogle from 'hooks/useGoogle';
import useKakao from 'hooks/useKakao';
import { ReactComponent as AccountIcon } from 'assets/icons/account.svg';
import cx from 'utils/cx';
import { tutorsLink } from 'utils/utils';
import Avatar from './ui/Avatar';
import FakeLink from './ui/FakeLink';

const LogoutNav = () => {
  const { t } = useTranslation('sidenav');

  return (
    <nav className="nav">
      <div className="account-sec">
        <div className="account-sec-left">
          <AccountIcon />
        </div>
        <div className="account-sec-right">
          <p>{t('welcome')}</p>
          <p>{t('loginPlease')}</p>
          <p>
            <Link to="/login">{t('login')}</Link>
          </p>
        </div>
      </div>
      <ul>
        <li>
          <Link to={tutorsLink}>과외</Link>
        </li>
        <li>
          <Link to="/partners">파트너</Link>
        </li>
        <li>
          <Link to="/discussions">{t('community')}</Link>
        </li>
        <li>
          <Link to="/stories">{t('story')}</Link>
        </li>
        <li>
          <Link to="/how-it-works">이용방법</Link>
        </li>
      </ul>
    </nav>
  );
};

const useOnLogout = ({ onClose }) => {
  const history = useHistory();
  const { disconnect } = useChat();
  const { googleSignOut } = useGoogle();
  const { kakaoSignOut } = useKakao();
  const logoutMutation = useLogout();
  const dispatch = useDispatch();

  const onLogout = () => {
    onClose();
    logoutMutation.mutate(undefined, {
      onSuccess: () => {
        disconnect();
        googleSignOut();
        kakaoSignOut();
        history.push('/');
        dispatch(setThisUser(null));
        if (window) {
          window.ReactNativeWebView?.postMessage(
            JSON.stringify({ type: 'userId', data: null }),
          );
        }
      },
    });
  };

  return onLogout;
};

const LoginNav = ({ onClose }) => {
  const { t } = useTranslation('sidenav');
  const { thisUser, isTutor } = useUser();

  const onLogout = useOnLogout({ onClose });

  return (
    <nav className="nav">
      <Link to="/mypage" className="account-sec">
        {isTutor && (
          <div className="account-sec-left">
            <Avatar src={thisUser?.picture} />
          </div>
        )}
        <div className="account-sec-right">
          <h2>{thisUser?.nickname || 'Nickname'}</h2>
          <p className="my-page-management">{t('myPage')}</p>
        </div>
      </Link>
      <ul>
        <li>
          <Link to="/chat">{t('chat')}</Link>
        </li>
        <li>
          <Link to={isTutor ? '/students' : tutorsLink}>과외</Link>
        </li>
        <li>
          <Link to="/partners">파트너</Link>
        </li>
        <li>
          <Link to="/discussions">{t('community')}</Link>
        </li>
        <li>
          <Link to="/stories">{t('story')}</Link>
        </li>
        <li>
          <FakeLink onClick={onLogout}>{t('logout')}</FakeLink>
        </li>
      </ul>
    </nav>
  );
};

LoginNav.propTypes = {
  onClose: PropTypes.func.isRequired,
};

const Sidenav = ({ isOpen, onClose }) => {
  const location = useLocation();
  const { thisUser } = useUser();

  useEffect(onClose, [location]);

  return (
    <aside className={cx('navigation-wrapper', { show: isOpen })}>
      <div
        className="backdrop-nav"
        tabIndex="-1"
        role="dialog"
        onClick={onClose}
        onKeyDown={onClose}
      />
      {thisUser ? <LoginNav onClose={onClose} /> : <LogoutNav />}
    </aside>
  );
};

Sidenav.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default memo(Sidenav);
