import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';

import API from '../../constants/API';
import axios from '../../constants/axios';
import { fetchThisUser } from '../../store/login';
import useSimpleLoading from '../useSimpleLoading';

const errorMessages = {
  2: '가입되어있지 않은 이메일 주소입니다.',
  3: '계정이 아직 활성화 되지 않았습니다. 이메일 확인하시고 계정을 활성화 해주세요.',
  4: '비밀번호가 잘못됐습니다',
  5: '정지된 계정입니다. 정지를 풀기 위해 고객센터에 연락해 주세요.',
};

const useAppleAPI = () => {
  const dispatch = useDispatch();
  const { onLoading, onLoadingEnd, onError } = useSimpleLoading(dispatch);

  const appleRegisterMutation = useMutation(
    ({ type, id_token }) => {
      return axios.post(`${API.registerApple}/${type}`, { id_token });
    },
    {
      onMutate: onLoading,
      onSettled: onLoadingEnd,
      onError,
      onSuccess: () => dispatch(fetchThisUser()),
    },
  );

  const appleLoginMutation = useMutation(
    ({ id_token }) => {
      return axios.post(API.loginApple, { id_token });
    },
    {
      onMutate: onLoading,
      onSettled: onLoadingEnd,
      onError: (e) => {
        const error = new Error(errorMessages[e.code] || e.message);
        onError(error);
      },
      onSuccess: () => dispatch(fetchThisUser()),
    },
  );

  return { appleRegisterMutation, appleLoginMutation };
};

export default useAppleAPI;
