import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';

import API from '../../constants/API';
import axios from '../../constants/axios';
import { fetchThisUser } from '../../store/login';
import useSimpleLoading from '../useSimpleLoading';

const useGoogleAPI = () => {
  const dispatch = useDispatch();
  const { onLoading, onLoadingEnd, onError } = useSimpleLoading(dispatch);

  const googleRegisterMutation = useMutation(
    ({ email, type, token }) => {
      return axios.post(API.registerGoogle, { email, type, token });
    },
    {
      onMutate: onLoading,
      onSettled: onLoadingEnd,
      onError,
      onSuccess: () => dispatch(fetchThisUser()),
    },
  );

  const googleLoginMutation = useMutation(
    ({ email, token }) => {
      return axios.post(API.loginGoogle, { email, token });
    },
    {
      onMutate: onLoading,
      onSettled: onLoadingEnd,
      onError,
      onSuccess: () => dispatch(fetchThisUser()),
    },
  );

  return { googleRegisterMutation, googleLoginMutation };
};

export default useGoogleAPI;
